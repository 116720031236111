import { useFormik } from "formik";
import { useEffect } from 'react';
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { InputNumberChecked } from "../Components/InputNumberChecked";
import { createPassword } from "../feathers";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useOrganizationsSelectionList } from "../Persistence/OrganizationsContext";

export const emptyUser = {
	id: null,
	email: '', username: '', password: '', displayname: '', permissions: '', roles: '', isAdmin: 0, loginAllowed: 1, fte: 1,
	active: 1, version: 1, forceChangePassword: 1, lastname: '', firstname: '', mobilenumber: '', phonenumber: '', phonenumberbusiness: '', organization: ''
}

export const UserForm = ({ user, setFormHasErrors, setUser, profileMode }) => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const organizationsSelectionList = useOrganizationsSelectionList();

	const formik = useFormik({

		initialValues: { ...emptyUser },
		validate: (data) => {
			let errors = {};

			if (!data.email) {
				errors.email = 'E-Mail muss gesetzt und eindeutig sein';
			}
			if (!data.username) {
				errors.username = 'Benutzername muss gesetzt und eindeutig sein';
			}
			if (!data.id && !data.password) {
				errors.password = 'Ein Passwort muss beim Anlegen gesetzt werden';
			}
			if (!data.isAdmin && !data.organization) {
				errors.organization = 'Organisation muss ausgefüllt sein';
			}
			if (!data.permissions) {
				errors.permissions = 'Rolle muss ausgefüllt sein';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null
			setFormHasErrors(hasErrors);
			if (!hasErrors) {
				setUser(data);
			}

			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
		validateOnBlur: true,
	});

	useEffect(() => {
		if (user !== null) {
			formik.setValues(user);
		} else {
			formik.setValues({ ...emptyUser, password: createPassword(false) })
		}
	}, [])

	return (
		<>
			<div className='col'>
				<div className="formgrid grid">
					<InputChangesChecked id='firstname' type="text" formik={formik} label='Vorname' setShowDialog={() => { }} />
					<InputChangesChecked id='lastname' type="text" formik={formik} label='Nachname' setShowDialog={() => { }} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='phonenumber' type="text" formik={formik} label='Telefon' setShowDialog={() => { }} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='mobilenumber' type="text" formik={formik} label='Mobil' setShowDialog={() => { }} />
					<div className="field col mr-3" />
				</div>
				{currentUser.permissions !== 'ADMIN' || profileMode ? '' :
					<>
						<div className="formgrid grid">
							<AutoCompleteChangesChecked label={'Organisation'} id={'organization'} itemLabel={'name'} formik={formik} dropdown={true} forceSelection={true} suggestionsList={organizationsSelectionList} setShowDialog={() => { }} />
						</div>
						<div className="formgrid grid">
							<InputChangesChecked id='username' type="text" formik={formik} label='Benutzername' setShowDialog={() => { }} />
							<DropdownChangesChecked label={'Rolle'} id={'permissions'} editmode={true} itemLabel={'display'} formik={formik} options={configurationsList.RolesList} setShowDialog={() => { }} handleChange={formik.handleChange} filter={false} />
						</div>
						<div className="formgrid grid">
							<InputChangesChecked id='email' label='E-Mail' type="text" formik={formik} setShowDialog={() => { }} />
						</div>
						<div className="formgrid grid">
							<DropdownChangesChecked className={'w-6'} editmode={currentUser.id !== formik.values.id} label='Benutzer kann sich anmelden' id={'loginAllowed'} itemLabel='display' formik={formik} options={[{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }]} setShowDialog={() => { }} filter={false} onChange={(value) => { if (!value) { formik.setFieldValue('fte', 0) } }} />
							{!formik.values.loginAllowed ? <div className="field col mr-3" /> : <InputNumberChecked id='fte' type="number" formik={formik} label='FTE' setShowDialog={() => { }} className='w-3rem' digits={2} showButtons={true} step={0.1} max={1} />}
						</div>
					</>}

				{user && user.id ? '' :
					<div className="formgrid grid">
						<InputChangesChecked id='password' label='initiales Passwort' type="text" formik={formik} setShowDialog={() => { }} />
					</div>}
			</div>
		</>
	)
}