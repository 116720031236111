import { useEffect, useState } from 'react';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { useUserList } from "../Persistence/UserContext"
import { getData, patchData } from '../feathers';
import { Dialog } from 'primereact/dialog';
import { UserForm } from './UserForm';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserUpdate } from '../Persistence/CurrentUserContext';
import { UserProfile } from './UserProfile';
import { ChangePassword } from '../Login/ChangePassword';
import { useNavigate } from 'react-router-dom';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';

export const UserAdministration = () => {

	const currentUser = useCurrentUser();
	const setCurrentUser = useCurrentUserUpdate();
	const configuratiosList = useConfigurationsList();
	const usersList = useUserList();
	const [user, setUser] = useState();
	const [filters, setFilters] = useState({
		'displayname': { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser.permissions !== 'ADMIN') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	const handleHidePasswordDialog = () => {
		setDisplayPasswordDialog(false);
	}

	const handleSelectUser = async (user) => {
		user.permissions = configuratiosList.RolesList.find((entry) => entry.alias === user.permissions)
		setUser(user);
		setDisplayUserDialog(true);
	}

	const handleChangePassword = async (id) => {
		await getData('users', id).then((_user) => {
			setUser(_user);
			setDisplayPasswordDialog(true);
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Nutzer ändern', icon: ENUM_ICONS.PENCIL, command: () => handleSelectUser({ ...rowData }), disabled: currentUser.permissions === 'READER' },];
		if (rowData.id !== currentUser.id) {
			items.push({ label: 'Passwort ändern', icon: ENUM_ICONS.PENCIL, command: () => handleChangePassword(rowData.id) });
		}

		if (rowData.active === 1) {
			items.push({ label: 'Nutzer*in deaktivieren', icon: ENUM_ICONS.CROSS, command: async () => { await setUser({ ...rowData }); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		} else {
			items.push({ label: 'Nutzer*in reaktivieren', icon: ENUM_ICONS.CHECK, command: async () => { await setUser({ ...rowData }); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		}
		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const renderPermission = (rowData) => {
		const permission = !configuratiosList.RolesList ? undefined : configuratiosList.RolesList.find((entry) => entry.alias === rowData.permissions)
		return !permission ? '' : permission.display
	}

	const renderDialogUserHeader = () => {
		return user && user.id ? 'Nutzerdaten ändern' : 'Neue Nutzer*in hinzufügen'
	}

	const patchUser = async (user) => {

		user.organizationId = user.organization.id
		user.permissions = user.permissions.alias
		user.displayname = user.firstname + " " + user.lastname
		await patchData('users', user).then((_user) => {
			if (currentUser.id === _user.id) {
				setCurrentUser(_user)
			}
			setDisplayUserDialog(false);
		})
	}

	const onClickNewUser = () => {
		setUser(null);
		setDisplayUserDialog(true);
	}

	return (
		<div className='mx-auto eaa-dataView'>
			<ConfirmDialog title='Status ändern' message={'Nutzer: ' + (user ? user.lastname + ', ' + user.firstname : '') + (user && user.active === 0 ? ' aktivieren?' : ' deaktivieren?')} labelOk='Ja'
				handleOnClick={() => { user.active = (user.active === 1 ? 0 : 1); patchUser(user) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-inputdialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
			</Dialog>
			<Dialog header={renderDialogUserHeader} visible={displayUserDialog} onHide={() => setDisplayUserDialog(false)} id="dialog-user-details" className='eaa-inputdialog' >
				<UserForm user={user} setUser={setUser} setFormHasErrors={setFormHasErrors} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayUserDialog(false)} />
					<Button onClick={() => patchUser(user)} disabled={formHasErrors} className="flex-order-0 ml-3 " label={user && user.id ? 'Speichern' : 'Neu anlegen'} />
				</div>
			</Dialog>
			{currentUser.permissions !== 'ADMIN' ?
				<UserProfile handleEditUser={handleSelectUser} handleChangePassword={handleChangePassword} /> : <>
					<div className="flex justify-content-end flex-wrap card-container">
						<div className="flexalign-self-center justify-content-left  my-2">
							<Button className='flex  text-white' label='Neue/r Nutzer*in hinzufügen' onClick={onClickNewUser} />
						</div>
					</div>
					<div className="card hidden md:inline-flex w-full" >
						<DataTable emptyMessage='keine Einträge gefunden' filters={filters} filterDisplay='row' scrollHeight={'calc(100vh - 200px)'} className='w-full' value={usersList} showGridlines stripedRows size="small" responsiveLayout="scroll" dataKey="id">
							<Column field='organization.name' header="Organisation"></Column>
							<Column body={(rowData) => { return rowData.lastname + ', ' + rowData.firstname }} filter filterField='displayname' showFilterMenu={false} filterPlaceholder="Suche nach Name" filterDisplay="row" header="Name"></Column>
							<Column body={renderPermission} header="Rolle"></Column>
							<Column className='w-8rem' body={(rowData) => { return <i className={rowData.active === 1 ? ENUM_ICONS.CHECK : ENUM_ICONS.CROSS} /> }} header="Status"></Column>
							<Column field="email" header="E-Mail"></Column>
							<Column body={renderContextMenu} style={{ width: '30px' }}></Column>
						</DataTable>
					</div>
				</>}

		</div>
	)
}