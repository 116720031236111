
import { AutoComplete } from "primereact/autocomplete";
import { classNames } from "primereact/utils"
import { useState } from "react";

export const AutoCompleteChangesChecked = ({ id, label, formik, idError, className, setShowDialog, forceSelection, dropdown, itemLabel, suggestionsList, handleChange, disabled, helptext, helptextcolor }) => {
	const [filteredList, setFilteredList] = useState(null);
	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block" dangerouslySetInnerHTML={{ __html: formik.errors[id] }} />;
	};
	const getFormHelpMessage = (name) => {
		return !isFormFieldValid(name) && helptext && <small style={{ color: helptextcolor }} className=" block" dangerouslySetInnerHTML={{ __html: helptext }} />;
	};

	const searchList = (event) => {
		setTimeout(() => {
			let _filteredList;
			if (!event.query.trim().length) {
				_filteredList = suggestionsList;
			}
			else {
				_filteredList = suggestionsList.filter((entry) => {
					return entry.active === 1 && entry[itemLabel].toLowerCase().includes(event.query.toLowerCase());
				});
			}

			setFilteredList(_filteredList);
		}, 250);
	}

	const itemTemplate = (item) => {
		return (
			<div className="selection-item">
				<div>{item[itemLabel]}</div>
			</div>
		);
	}

	return (
		<div className="field col mr-3">
			<label htmlFor={id} className='block'>{label}</label>
			<AutoComplete className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + className} id={id} disabled={disabled} value={formik.values[id]} suggestions={filteredList} completeMethod={searchList} field={itemLabel} dropdown={dropdown} forceSelection={forceSelection} itemTemplate={itemTemplate} onChange={async (e) => { if (!handleChange) { setShowDialog(true); formik.handleChange(e) } else { await handleChange(e) } }} aria-label={id} />
			{getFormErrorMessage(idError ? idError : id)}
			{getFormHelpMessage(idError ? idError : id)}
		</div>
	)

}

