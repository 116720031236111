import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AddressesDataview } from './Addresses/AddressesDataview';
import { AddressesForm } from './Addresses/AddressesForm';
import { Application } from './Application';
import { CompaniesData } from './Companies/CompaniesData';
import { CompaniesDataview } from './Companies/CompaniesDataview';
import { Dashboard } from './Dashboard/Dashboard';
import { EAACaseCreate } from './EAACase/EAACaseCreate';
import { EAACaseDataview } from './EAACase/EAACaseDataview';
import { EAACaseSheet } from './EAACase/EAACaseSheet';
import { StatusFilterProvider } from './EAACase/StatusFilterContext';
import './index.scss';
import { JobOpeningData } from './JobOpenings/JobOpeningData';
import { JobOpeningDataview } from './JobOpenings/JobOpeningsDataView';
import { ENUM_ROUTES } from './Navigation/Routes';
import { CompaniesProvider } from './Persistence/CompaniesContext';
import { CurrentUserProvider } from './Persistence/CurrentUserContext';
import { EAACasesProvider } from './Persistence/EAACasesContext';
import { JobOpeningsProvider } from './Persistence/JobOpeningsContext';
import { LoginProvider } from './Persistence/LoginContext';
import { UserProvider } from './Persistence/UserContext';
import { ProviderActivities } from './ProviderActivities/ProviderActivities';
import reportWebVitals from './reportWebVitals';
import { Settings } from './Settings/Settings';
import { Statistic } from './Statistic/Statistic';
import { UserAdministration } from './UserAdministration/UserAdministration';
import { UserProfile } from './UserAdministration/UserProfile';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<CurrentUserProvider><LoginProvider><Application /></LoginProvider></CurrentUserProvider>}>
					<Route index element={<StatusFilterProvider><Dashboard /></StatusFilterProvider>} />
					<Route path={`${ENUM_ROUTES.DASHBOARD}`} element={<StatusFilterProvider><Dashboard /></StatusFilterProvider>} />
					<Route path={`${ENUM_ROUTES.COMPANIES}`} element={<CompaniesProvider><CompaniesDataview /></CompaniesProvider>} />
					<Route path={`${ENUM_ROUTES.COMPANIES}/:companyId`} element={<StatusFilterProvider><CompaniesData /></StatusFilterProvider>} />
					<Route path={`${ENUM_ROUTES.ADDRESSES}`} element={<AddressesDataview />} />
					<Route path={`${ENUM_ROUTES.ADDRESSES}/:addressId`} element={<AddressesForm />} />
					<Route path={`${ENUM_ROUTES.SETTINGS}`} element={<Settings />} />
					<Route path={`${ENUM_ROUTES.EAACASES}`} element={<StatusFilterProvider><EAACasesProvider><EAACaseDataview /></EAACasesProvider></StatusFilterProvider>} />
					<Route path={`${ENUM_ROUTES.EAACASES}/:eaaCaseId`} element={<EAACaseSheet />} />
					<Route path={`${ENUM_ROUTES.EAACASES}/new`} element={<EAACaseCreate />} />
					<Route path={`${ENUM_ROUTES.STATISTICS}`} element={<Statistic />} />
					<Route path={`${ENUM_ROUTES.PROVIDERACTIVITIES}`} element={<ProviderActivities />} />
					<Route path={`${ENUM_ROUTES.JOBPROFILES}`} element={<JobOpeningsProvider><JobOpeningDataview /></JobOpeningsProvider>} />
					<Route path={`${ENUM_ROUTES.JOBPROFILES}/:jobOpeningId`} element={<JobOpeningData />} />
					<Route path={`${ENUM_ROUTES.USERADMINISTRATION}`} element={<UserProvider><UserAdministration /></UserProvider>} />
					<Route path={`${ENUM_ROUTES.USERPROFILE}`} element={<UserProfile />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode >,
	document.getElementById('root')
);


reportWebVitals();
