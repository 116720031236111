import { endOfDay, format, getTime } from 'date-fns';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { useState, useRef } from 'react';
import { ENUM_ICONS } from '../Components/Icons';
import { getData, patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { CompaniesProvider, useCompaniesList } from '../Persistence/CompaniesContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { CompanyForm } from './CompanyForm';

const mapStatus = { '1': 'color-tag-green', '0': 'color-tag-grey' };
const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];

export const CompaniesDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();

	const calendarRef = useRef();
	const currentUser = useCurrentUser();
	const companiesList = useCompaniesList();
	const [paginationFirst, setPaginationFirst] = useState(0);
	const [paginationRows, setPaginationRows] = useState(15);
	const [company, setCompany] = useState();
	const [filterCreatedValues, setFilterCreatedValues] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();

	const [filters, setFilters] = useState({
		'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN },
		'active': { value: (null), matchMode: FilterMatchMode.EQUALS },
	});

	const handleSelectCompany = async (id) => {
		await getData('companies', id).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + id);
		})
	}

	const patchCompany = async () => {
		company.regionId = company.region.id
		await patchData('companies', company, currentUser).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
		})
	}

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (
				<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
					{options.first} - {options.last} von {options.totalRecords}
				</span>
			)
		}
	};

	const statusItemTemplate = (option) => {
		return <Tag className={"mr-2 text-white px-3 " + mapStatus[option.alias]} rounded value={option.display} />
	}

	const statusRowFilterTemplate = (options) => {
		return <Dropdown value={options.value} className='w-10rem' optionLabel="display" optionValue="alias" options={listStatus} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder='Status wählen' />;
	}
	const sortStatus = (event) => {
		let data = [...companiesList];
		data.sort((data1, data2) => {
			const value1 = data1[event.field];
			const value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1.alias === 'string' && typeof value2.alias === 'string')
				result = value1.alias.localeCompare(value2.alias, undefined, { numeric: true });
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
		return data;
	}
	const createdFilterTemplate = (options) => {
		return (
			<div className='flex w-8rem filterCreatedCalendar'>
				<div id='displayFilterDates' className="flex flex-column flex-grow-1 justify-content-start font-normal text-sm pr-2 m-0  display-dates">
					<span className='flex justify-content-center'>{options.value && options.value[0] ? format(options.value[0], 'dd.MM.yyyy') : null}</span>
					<span className='flex justify-content-center'>{options.value && options.value[1] ? format(options.value[1], 'dd.MM.yyyy') : null}</span>
				</div>
				<div className='flex overflow-hidden' style={{ width: '38px' }}>
					<Calendar id='calendarFilterDates' style={{ minWidth: '100px' }} showIcon iconPos='left' value={filterCreatedValues} ref={calendarRef} dateFormat="dd.mm.yy" selectionMode='range' readOnlyInput maxDate={new Date()}
						onChange={(e) => {
							const values = [e.value[0] ? getTime(e.value[0]) : null, e.value[1] ? getTime(endOfDay(e.value[1])) : null]
							setFilterCreatedValues(e.value)
							options.filterApplyCallback(values);
							if (e.value[1] !== null) { calendarRef.current.hide() }
						}}
					/>
				</div>

			</div>

		)
	}
	const renderCreatedDate = (rowData) => {
		return rowData.millisecondsCreatedAt ? format(rowData.millisecondsCreatedAt, 'yyyy-MM-dd') : ''
	}
	const renderStatus = (rowData) => {
		const status = listStatus.find(entry => entry.alias === rowData.active);
		return <div className="w-full flex justify-content-between flex-wrap card-container">
			<div className="flex align-items-start">
				<Tag className={"text-white px-3 " + mapStatus[status.alias]} rounded value={status.display} />
			</div>

		</div>
	}
	const renderOpenCompany = (rowData) => {
		return <div className="flex justify-content-center w-full" onClick={() => { handleSelectCompany(rowData.id) }}>
			<i className={ENUM_ICONS.EYE} />
		</div>

	}
	return (
		<div className='mx-auto eaa-dataView select-none'>
			<Dialog header='Neues Unternehmen anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-company-details" className='eaa-inputdialog' >
				<CompaniesProvider><CompanyForm editmode={true} setFormHasErrors={setFormHasErrors} setCompanyForm={setCompany} handleSelectExistingCompany={() => { setDisplayCompanyDialog(false) }} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={() => { }} /></CompaniesProvider>
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchCompany} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
			{!company ? '' : <ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={company.name} serviceId={company.id} serviceName='companies' userId={currentUser.id} />}
			<div className="flex justify-content-end flex-wrap card-container">
				<div className="flex align-self-center justify-content-left  my-2">
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { setDisplayCompanyDialog(true) }} className='flex  text-white' label='Neues Unternehmen anlegen' />
				</div>
			</div>
			<div className="card flex md:inline-flex w-full" >
				<DataTable emptyMessage='keine Einträge gefunden' removableSort filters={filters} paginator paginatorTemplate={paginatorTemplate} first={paginationFirst} rows={paginationRows} scrollHeight={'calc(100vh - 200px)'} className='w-full' value={companiesList} showGridlines stripedRows size="small" filterDisplay='row' responsiveLayout="scroll" dataKey="id" selection={company}>
					<Column className='w-11rem' sortable field="id" body={(row) => ('#UN' + row.id)} filter header="Unternehmens-ID" filterPlaceholder="ID suchen" showFilterMenu={false}></Column>
					<Column field="name" sortable filter filterPlaceholder="Unternehmen suchen" header="Name" showFilterMenu={false}></Column>
					<Column align='right' sortable field="employees" header="Mitarbeiter"></Column>
					<Column align='right' sortable field="employeesSbM" header="SbM"></Column>
					<Column align='right' sortable body={(rowData) => { return rowData.quote.toLocaleString("de-DE", { style: "percent" }) }} field="quote" header="Quote"></Column>
					<Column className='w-5rem' sortable filter field='millisecondsCreatedAt' showFilterMenu={false} filterElement={createdFilterTemplate} body={renderCreatedDate} onFilterClear={() => setFilterCreatedValues(null)} header="Anlagedatum" ></Column>
					<Column field="region.name" sortable filter filterField='region.name' showFilterMenu={false} header="EAA-Region" filterPlaceholder="Region suchen"></Column>
					<Column className='w-10rem' sortable sortFunction={sortStatus} filter field="active" filterElement={statusRowFilterTemplate} showFilterMenu={false} header="Status" body={renderStatus}></Column>
					<Column className='open-icon justify-content-center w-3rem p-0' body={renderOpenCompany}></Column>
				</DataTable>
			</div>
		</div>
	)

}