import React, { useContext, useState, useEffect } from "react";
import { StatusFilterProvider } from "../EAACase/StatusFilterContext";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const listenernames = ['created', 'patched'];
const providername = 'EAACasesProvider';
const servicename = 'eaa-cases';
const EAACasesContext = React.createContext();

export function useEAACasesList() {
	return useContext(EAACasesContext);
}

export function EAACasesProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();

	let isMounted = true;

	useEffect(() => {
		if (listItemChanged) {
			console.log('EAACasesProvider listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		LoggerContext.debug(`########### ${providername} useEffect`);
		getData(servicename).then((_list) => {
			if (isMounted) {
				setList(_list);
			}

		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		return () => { isMounted = false; updateServiceListener(servicename, listenernames, null); }
	}, [isMounted])

	return (
		<EAACasesContext.Provider value={list}>
			{children}
		</EAACasesContext.Provider>
	)
}