export enum ENUM_ICONS {
	REACTIVATE = ' fa fa-rotate-left ', TRASH = ' fa fa-trash-o ', PENCIL = ' fa fa-pencil-square-o ',
	EXPORT = ' fa fa-external-link ', CALENDAR = ' fa fa-calendar ', CLOCK = ' fa fa-clock-o ', EYE = ' fa fa-eye ',
	PLUS = ' fa fa-plus ', PLUS_CIRCLE = ' fa fa-plus-circle ', CHECK = ' fa fa-check ', CHECK_SQUARE = ' fa fa-check-square-o ',
	CROSS = ' fa fa-times ', FOLDER_OPEN = ' fa fa-folder-open ', FOLDER_OPEN_O = ' fa fa-folder-open-o ',
	PHONE = ' fa fa-phone ', FAX = ' fa fa-fax ', ENVELOPE = ' fa fa-envelope-o ', MOBILE = ' fa fa-mobile-phone fa-lg ',
	JOBPROFILE = ' fa fa-graduation-cap ', COMPANY = ' fa fa-building ', EAACASE = ' fa fa-folder-open ', USER = ' fa fa-user ',
	CASE_USER = ' fa fa-shield ', EURO = ' fa fa-eur ', JOBTYPE = ' fa fa-tag ', LOCATION = ' fa fa-thumb-tack ',
	DASHBOARD = ' fa fa-home ', PROVIDERACTIVITIES = ' fa fa-sitemap ', USERADMINISTRATION = ' fa fa-users ', SETTINGS = ' fa fa-cogs ',
	STATISTICS = ' fa fa-line-chart '
};
