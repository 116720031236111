import { useFormik } from 'formik';
import { TabPanel, TabView } from 'primereact/tabview';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { InputField } from '../Components/InputField';
import { getData, patchData } from '../feathers';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useOrganizationsList } from '../Persistence/OrganizationsContext';
import { useRegionsList } from '../Persistence/RegionsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { Checkbox } from 'primereact/checkbox';

export const emptyRegion = { id: null, active: 1, version: 1, name: '' }
export const emptyOrganization = { id: null, active: 1, version: 1, name: '', type: '', orgtype: '', ignoreInStatistics: false }

export const Settings = () => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const regionsList = useRegionsList();
	const organizationsList = useOrganizationsList();
	const [hasErrors, setHasErrors] = useState(false);
	const [toggleEntry, setToggleEntry] = useState(false);
	const [hasOrganizationErrors, setHasOrganizationErrors] = useState(false);
	const [displayRegionDialog, setDisplayRegionDialog] = useState(false);
	const [displayOrganizationDialog, setDisplayOrganizationDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [paginationFirstRegion, setPaginationFirstRegion] = useState(0);
	const [paginationRowsRegion, setPaginationRowsRegion] = useState(15);
	const [paginationFirstOrg, setPaginationFirstOrg] = useState(0);
	const [paginationRowsOrg, setPaginationRowsOrg] = useState(15);
	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser.permissions !== 'ADMIN') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	const formikRegion = useFormik({
		initialValues: {
			...emptyRegion
		},
		validate: (data) => {
			let errors = {};
			if (!data.name) {
				errors.name = 'Name ist ein Pflichtfeld';
			}
			setHasErrors(Object.keys(errors).length !== 0)
			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const formikOrganization = useFormik({
		initialValues: {
			...emptyOrganization
		},
		validate: (data) => {
			let errors = {};
			if (!data.name) {
				errors.name = 'Name ist ein Pflichtfeld';
			}
			if (!data.orgtype) {
				errors.orgtype = 'Typ ist ein Pflichtfeld';
			}
			setHasOrganizationErrors(Object.keys(errors).length !== 0)
			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const [filtersRegion, setFiltersRegion] = useState({
		'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [filters, setFilters] = useState({
		'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'type': { value: (null), matchMode: FilterMatchMode.EQUALS },
	});

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (
				<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
					{options.first} - {options.last} von {options.totalRecords}
				</span>
			)
		}
	};

	const toggleStatus = async () => {
		toggleEntry.active = (toggleEntry.active ? 0 : 1);
		await patchData(toggleEntry.servicename, toggleEntry);
		setDisplayConfirmDialog(false);
	}

	const handleStatusChange = async (entry, servicename, objectName) => {
		const command = (entry.active ? 'deaktivieren' : 'reaktivieren');
		const confirmTitle = objectName + ' ' + command
		const confirmMessage = `Sind Sie sicher,\n ${objectName + ': ' + entry.name}\nzu ${command}?`
		setToggleEntry({ ...entry, servicename, confirmTitle, confirmMessage });
		setDisplayConfirmDialog(true);
	}

	const patchOrganization = async () => {
		const org = { ...formikOrganization.values, type: formikOrganization.values.orgtype.alias, ignoreInStatistics: formikOrganization.values.ignoreInStatistics ? 1 : 0 };
		await patchData('organizations', org).then(() => {
			setDisplayOrganizationDialog(false)
		})
	}
	const patchRegion = async () => {
		await patchData('regions', formikRegion.values).then(() => {
			setDisplayRegionDialog(false)
		})
	}

	const editOrganization = (organization) => {
		organization.orgtype = configurationsList.OrganizationTypesList.find(entry => entry.alias === organization.type)
		organization.ignoreInStatistics = (organization.ignoreInStatistics === 1)
		formikOrganization.setValues(organization);
		setDisplayOrganizationDialog(true)
	}

	const jobTypeRowFilterTemplate = (options) => {
		const listStatus = configurationsList.OrganizationTypesList;
		return <Dropdown value={options.value} className='w-20rem' optionLabel="display" optionValue="alias" options={listStatus} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={jobTypeItemTemplate} />;
	}
	const jobTypeItemTemplate = (option) => {
		return <div>{option.display}</div>
	}
	const renderRegionEdit = (rowData) => {
		return <div className='flex justify-content-between'>
			<div className='edit-icon'>	<i className={'flex' + ENUM_ICONS.PENCIL} onClick={() => { formikRegion.setValues(rowData); setDisplayRegionDialog(true) }} /></div>
			<div className='del-icon'><i className={'flex ' + (rowData.active ? ENUM_ICONS.TRASH + 'del-icon' : ENUM_ICONS.REACTIVATE + 'check-icon')} onClick={() => { handleStatusChange(rowData, 'regions', 'Region') }} /></div>
		</div>
	}
	const renderOrganizationEdit = (rowData) => {
		return <div className='flex justify-content-between'>
			<div className='edit-icon'>	<i className={'flex ' + ENUM_ICONS.PENCIL} onClick={() => { editOrganization(rowData); }} /></div>
			<div className='del-icon'><i className={'flex ' + (rowData.active ? ENUM_ICONS.TRASH + 'del-icon' : ENUM_ICONS.REACTIVATE + 'check-icon')} onClick={() => { handleStatusChange(rowData, 'organizations', 'Organisation') }} /></div>
		</div>
	}

	const renderIgnoreInStatistics = (rowData) => {
		return <i className={(rowData.ignoreInStatistics ? ENUM_ICONS.CROSS + ' text-red-600' : ENUM_ICONS.STATISTICS) + ' flex justify-content-center'} />
	}

	const renderType = (rowData) => {
		const orgType = configurationsList.OrganizationTypesList ? configurationsList.OrganizationTypesList.find(entry => entry.alias === rowData.type) : {};
		return <div className="flex align-items-start">{orgType ? orgType.display : rowData.type}</div>
	}
	return (
		<div className={currentUser.permissions !== 'ADMIN' ? 'hidden' : ''}>
			<div className='mx-auto eaa-dataView flex gap-4'>
				<TabView className="w-full gap-2">
					<TabPanel header="Regionen" >
						<div className='flex flex-column gap-2'>
							<div className='flex justify-content-end'>
								<Button className='' disabled={currentUser.permissions !== 'ADMIN'} label='Neue Region anlegen' onClick={() => { formikRegion.setValues({ ...emptyRegion }); setDisplayRegionDialog(true) }} />
							</div>
							<div className='flex'>
								<DataTable emptyMessage='keine Einträge gefunden' value={regionsList} filterDisplay="row" filters={filtersRegion} paginator paginatorTemplate={paginatorTemplate} first={paginationFirstRegion} rows={paginationRowsRegion} scrollHeight={'calc(100vh - 200px)'} showGridlines stripedRows size="small" className='w-full' responsiveLayout="scroll" dataKey="id" selectionMode="single">
									<Column field="name" filter filterPlaceholder="Suche nach Name" showFilterMenu={false} header="Name"></Column>
									<Column className='w-6rem' body={renderRegionEdit} header=""></Column>
								</DataTable>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Organisationen">
						<div className='flex flex-column gap-2'>
							<div className='flex justify-content-end'>
								<Button className='' label='Neue Organisation anlegen' onClick={() => { formikOrganization.setValues({ ...emptyOrganization }); setDisplayOrganizationDialog(true) }} />
							</div>
							<div className='flex'>
								<DataTable emptyMessage='keine Einträge gefunden' removableSort value={organizationsList} filters={filters} paginator paginatorTemplate={paginatorTemplate} first={paginationFirstOrg} rows={paginationRowsOrg} scrollHeight={'calc(100vh - 200px)'} showGridlines stripedRows filterDisplay='row' size="small" className='w-full' responsiveLayout="scroll" dataKey="id" selectionMode="single">
									<Column className='w-7rem' sortable field='ignoreInStatistics' body={renderIgnoreInStatistics} showFilterMenu={false} header="Statistik"></Column>
									<Column field="name" sortable filter filterPlaceholder="Suche nach Name" showFilterMenu={false} header="Name"></Column>
									<Column className='w-20rem' sortable field='type' filterField='type' body={renderType} filter filterElement={jobTypeRowFilterTemplate} showFilterMenu={false} header="Typ"></Column>
									<Column className='w-6rem' body={renderOrganizationEdit} header=""></Column>
								</DataTable>
							</div>
						</div>
					</TabPanel>
				</TabView>
			</div>

			<ConfirmDialog title={toggleEntry.confirmTitle} message={toggleEntry.confirmMessage} labelOk='Ja'
				handleOnClick={toggleStatus} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={formikRegion.values.id ? 'Region ändern' : 'Region neu anlegen'} visible={displayRegionDialog} onHide={() => setDisplayRegionDialog(false)} id="dialog-eaacase" className='eaa-inputdialog' >
				<div className='flex font-light mb-3 p-0 '>{formikRegion.values.id ? 'Hier können sie eine die Region ' + formikRegion.values.name + ' ändern.' : 'Hier können sie eine Region neu anlegen'}</div>
				<div className="formgrid grid  m-0 p-0">
					<InputField formik={formikRegion} id='name' label='Name' type='text' />
				</div>
				<div className="flex flex-row flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayRegionDialog(false)} />
					<Button disabled={hasErrors} onClick={patchRegion} className="flex-order-0 ml-3 " label={formikRegion.values.id ? 'Ändern' : 'Neu anlegen'} />
				</div>
			</Dialog>


			<Dialog header={formikOrganization.values.id ? 'Organisation ändern' : 'Organisation neu anlegen'} visible={displayOrganizationDialog} onHide={() => setDisplayOrganizationDialog(false)} id="dialog-eaacase" className='eaa-inputdialog' >
				<div className='flex font-light mb-3 p-0 '>{formikOrganization.values.id ? 'Hier können sie eine die Organisation ' + formikOrganization.values.name + ' ändern.' : 'Hier können sie eine Organisation neu anlegen'}</div>
				<div className="formgrid grid  m-0 p-0">
					<InputField formik={formikOrganization} id='name' label='Name' type='text' />
					<DropdownChangesChecked editmode={true} formik={formikOrganization} id='orgtype' itemLabel='display' label='Typ' options={configurationsList.OrganizationTypesList} setShowDialog={() => { }} key='orgtype' />
					<div className="field col-fixed w-2">
						<label htmlFor='htmlfor_ignoreInStatistics' className='block'>In Statistik</label>
						<div className="field-checkbox pt-1">
							<Checkbox inputId="ignoreInStatistics" checked={formikOrganization.values.ignoreInStatistics} onChange={e => formikOrganization.setFieldValue('ignoreInStatistics', e.checked)} />
							<label htmlFor="ignoreInStatistics">ignorieren</label>
						</div>
					</div>
				</div>

				<div className="flex flex-row flex-row-reverse">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayOrganizationDialog(false)} />
					<Button disabled={hasOrganizationErrors} onClick={patchOrganization} className="flex-order-0 ml-3 " label={formikOrganization.values.id ? 'Ändern' : 'Neu anlegen'} />
				</div>
			</Dialog>
		</div>
	)

}