import { ENUM_ICONS } from "../Components/Icons";

export enum ENUM_ROUTES {
	JOBPROFILES = 'Stellenprofile', STATISTICS = 'Statistik', PROVIDERACTIVITIES = 'Traegeraktivitaeten', COMPANIES = 'Unternehmen',
	EAACASES = 'Fallverwaltung', DASHBOARD = 'Dashboard', ADDRESSES = 'Adressen', USERADMINISTRATION = 'Nutzerverwaltung', SETTINGS = 'Einstellungen', USERPROFILE = 'Benutzerprofil'
};

export const ROUTES = {
	JOBPROFILES: { label: 'Stellenprofile', icon: ENUM_ICONS.JOBPROFILE, route: ENUM_ROUTES.JOBPROFILES, service: 'job-openings' },
	STATISTICS: { label: 'Statistik', icon: ENUM_ICONS.STATISTICS, route: ENUM_ROUTES.STATISTICS },
	PROVIDERACTIVITIES: { label: 'Traegeraktivitaeten', icon: ENUM_ICONS.PROVIDERACTIVITIES, route: ENUM_ROUTES.PROVIDERACTIVITIES },
	COMPANIES: { label: 'Unternehmen', icon: ENUM_ICONS.COMPANY, route: ENUM_ROUTES.COMPANIES, service: 'companies' },
	EAACASES: { label: 'Fallverwaltung', icon: ENUM_ICONS.EAACASE, route: ENUM_ROUTES.EAACASES, service: 'eaa-cases' },
	DASHBOARD: { label: 'Dashboard', icon: ENUM_ICONS.DASHBOARD, route: ENUM_ROUTES.DASHBOARD },
	ADDRESSES: { label: 'Adressen', icon: '', route: ENUM_ROUTES.ADDRESSES },
	USERADMINISTRATION: { label: 'Nutzerverwaltung', icon: ENUM_ICONS.USERADMINISTRATION, route: ENUM_ROUTES.USERADMINISTRATION },
	SETTINGS: { label: 'Einstellungen', icon: ENUM_ICONS.SETTINGS, route: ENUM_ROUTES.SETTINGS },
	USERPROFILE: { label: 'Benutzerprofil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.USERPROFILE }
}