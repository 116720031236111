import React, { useContext, useState, useEffect } from "react";

const StatusFilterContext = React.createContext();
const StatusFilterUpdateContext = React.createContext();

export function useStatusFilter() {
	return useContext(StatusFilterContext);
}

export function useStatusFilterUpdate() {
	return useContext(StatusFilterUpdateContext);
}

export function StatusFilterProvider({ children }) {
	const [statusFilter, setStatusFilter] = useState(null);

	return (
		<StatusFilterContext.Provider value={statusFilter}>
			<StatusFilterUpdateContext.Provider value={setStatusFilter}>
				{children}
			</StatusFilterUpdateContext.Provider>
		</StatusFilterContext.Provider>
	)
}