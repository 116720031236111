import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const listenernames = ['created', 'patched'];
const providername = 'CasePersonsProvider';
const servicename = 'case-persons';
const CasePersonsContext = React.createContext();

export function useCasePersonsList() {
	return useContext(CasePersonsContext);
}

export function CasePersonsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();

	let isMounted = true;

	useEffect(() => {
		if (listItemChanged) {
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		LoggerContext.debug(`########### ${providername} useEffect`);
		getData(servicename).then((_list) => {
			if (isMounted) {
				setList(_list);
			}
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		return () => { updateServiceListener(servicename, listenernames, null); }
	}, [isMounted])
	return (
		<CasePersonsContext.Provider value={list}>
			{children}
		</CasePersonsContext.Provider>
	)
}