import React, { useContext, useState, useEffect } from "react";
import { getData } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const providername = 'ConfigurationsProvider';

const ConfigurationsContext = React.createContext();

export function useConfigurationsList() {
	return useContext(ConfigurationsContext);
}

export function ConfigurationsProvider({ children }) {
	const [enumsList, setConfigurationsList] = useState([]);

	useEffect(() => {
		LoggerContext.debug('########### ConfigurationsProvider useEffect');
		getData('configurations').then((_list) => {
			setConfigurationsList(_list);
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}, [])

	return (
		<ConfigurationsContext.Provider value={enumsList}>
			{children}
		</ConfigurationsContext.Provider>
	)
}