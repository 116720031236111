import { endOfDay, format, getTime, parseISO } from 'date-fns';
import { FilterService } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { useEffect, useRef, useState } from 'react';
import { ResponsibleMultiSelect } from '../Components/ResponsibleMultiSelect';
import { getData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useEAACasesList } from '../Persistence/EAACasesContext';
import { UserProvider } from '../Persistence/UserContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { useStatusFilter, useStatusFilterUpdate } from './StatusFilterContext';
import { ENUM_ICONS } from '../Components/Icons';

export const EAACaseDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const eaaCasesList = useEAACasesList();
	const configurationsList = useConfigurationsList();
	const statusFilter = useStatusFilter();
	const setStatusFilter = useStatusFilterUpdate();
	const [paginationFirst, setPaginationFirst] = useState(0);
	const [paginationRows, setPaginationRows] = useState(15);
	const [filterCreatedValues, setFilterCreatedValues] = useState();
	const calendarRef = useRef();
	const [filters, setFilters] = useState();
	const [eaaCase, setEaaCase] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);


	useEffect(() => {
		setFilters({
			'caseDocId': { value: null, matchMode: FilterMatchMode.CONTAINS },
			'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
			'status': { value: (statusFilter && statusFilter.hasOwnProperty('status') ? [statusFilter.status] : null), matchMode: FilterMatchMode.IN },
			'company.name': { value: (statusFilter && statusFilter.hasOwnProperty('company') ? [statusFilter.company] : null), matchMode: FilterMatchMode.CONTAINS },
			'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN },
			'responsible': { value: (!statusFilter || !statusFilter.hasOwnProperty('responsible') ? [(currentUser.lastname + ', ' + currentUser.firstname)] : statusFilter && statusFilter.responsible ? [statusFilter.responsible] : null), matchMode: FilterMatchMode.IN },
		});
		setStatusFilter(null);
	}, [])


	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (
				<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
					{options.first} - {options.last} von {options.totalRecords}
				</span>
			)
		}
	};

	const renderOpenLink = (rowData) => {
		return <div className="flex justify-content-center w-full" onClick={() => { handleOpenEAACase(rowData.id) }}>
			<i className={ENUM_ICONS.EYE} />
		</div>
	}

	const renderStatus = (rowData) => {
		return <div className="w-full flex justify-content-between flex-wrap card-container">
			<Tag className="text-white px-3" style={{ backgroundColor: rowData.status.color }} rounded value={rowData.status.display} />
		</div>
	}

	const renderCreatedDate = (rowData) => {
		return rowData.millisecondsCreatedAt ? format(rowData.millisecondsCreatedAt, 'yyyy-MM-dd') : ''
	}
	const renderResponsible = (rowData) => {
		return (rowData.responsible && typeof rowData.responsible !== 'string' ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : (rowData.responsible ? rowData.responsible : 'n.n.'))
	}

	const handleOpenEAACase = async (id) => {

		await getData('eaa-cases', id).then((eaacase) => {
			const label = eaacase.company.name + " | " + eaacase.name;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) + '...' : label}`, `${ENUM_ROUTES.EAACASES}/` + id);
		})
	}

	const selectedTemplate = (option) => {
		return !option ? '' :
			<div className="flex text-xm" style={{ color: option.color }}>
				{option.display}
			</div>
	}
	const statusRowFilterTemplate = (options) => {
		const listStatus = configurationsList && configurationsList.CaseStatusList ? configurationsList.CaseStatusList : null
		return <div className='flex w-10rem'>
			<MultiSelect className='flex w-full' id='statusRowFilterTemplate' value={options.value} optionLabel="display" options={listStatus} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} selectedItemTemplate={selectedTemplate} placeholder='Status wählen' selectedItemsLabel={'alle'} maxSelectedLabels={4} />
		</div>

	}
	const responsibleFilterTemplate = (options) => {
		return <UserProvider><ResponsibleMultiSelect value={options.value} onChange={(value) => options.filterApplyCallback(value)} /></UserProvider>
	}



	const createdFilterTemplate = (options) => {
		return (
			<div className='flex w-8rem filterCreatedCalendar'>
				<div id='displayFilterDates' className="flex flex-column flex-grow-1 justify-content-start font-normal text-sm pr-2 m-0  display-dates">
					<span className='flex justify-content-center'>{options.value && options.value[0] ? format(options.value[0], 'dd.MM.yyyy') : null}</span>
					<span className='flex justify-content-center'>{options.value && options.value[1] ? format(options.value[1], 'dd.MM.yyyy') : null}</span>
				</div>
				<div className='flex overflow-hidden' style={{ width: '38px' }}>
					<Calendar id='calendarFilterDates' style={{ minWidth: '100px' }} showIcon iconPos='left' value={filterCreatedValues} ref={calendarRef} dateFormat="dd.mm.yy" selectionMode='range' readOnlyInput maxDate={new Date()}
						onChange={(e) => {
							const values = [e.value[0] ? getTime(e.value[0]) : null, e.value[1] ? getTime(endOfDay(e.value[1])) : null]
							setFilterCreatedValues(e.value)
							options.filterApplyCallback(values);
							if (e.value[1] !== null) { calendarRef.current.hide() }
						}}
					/>
				</div>

			</div>

		)
	}
	const sortStatus = (event) => {
		let data = [...eaaCasesList];
		data.sort((data1, data2) => {
			const value1 = data1[event.field];
			const value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1.alias === 'string' && typeof value2.alias === 'string')
				result = value1.alias.localeCompare(value2.alias, undefined, { numeric: true });
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});

		return data;
	}

	const statusItemTemplate = (option) => {
		return !option ? '' : <Tag className={'mr-2 text-white px-3'} style={{ backgroundColor: option.color }} rounded value={option.display} />
	}

	return (

		<div className='mx-auto eaa-dataView'>
			{!eaaCase ? '' :
				<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Fall' name={eaaCase.name + ' - ' + eaaCase.company.name} serviceId={eaaCase.id} serviceName='eaa-cases' userId={currentUser.id} />
			}

			<div className={!eaaCasesList ? 'hidden' : ''}>
				<div className="flex justify-content-end flex-wrap card-container">
					<div className="flex align-self-center justify-content-left my-2">
						<Button disabled={currentUser.permissions === 'READER'} className='flex text-white' label='Neuen Fall anlegen' onClick={() => { addBreadcrumb('Neuen Fall anlegen', `${ENUM_ROUTES.EAACASES}/new`); }} />
					</div>
				</div>
				<div className="card flex md:inline-flex w-full">
					<DataTable id='eaacase-datatable' removableSort emptyMessage='keine Einträge gefunden' filters={filters} paginator paginatorTemplate={paginatorTemplate} first={paginationFirst} rows={paginationRows} scrollHeight={'calc(100vh - 200px)'} value={eaaCasesList} showGridlines stripedRows filterDisplay='row' size="small" className='w-full' responsiveLayout="scroll" dataKey="id" selectionMode="single" selection={eaaCase} >
						<Column className='w-9rem' sortable field="caseDocId" header="Fall ID" filter showFilterMenu={false} filterPlaceholder='ID suchen' />
						<Column className='w-8rem' sortable filter field='responsible' filterElement={responsibleFilterTemplate} showFilterMenu={false} header="Berater*in" body={renderResponsible}></Column>
						<Column header="Unternehmen" sortable sortField='company.name' filter filterField="company.name" showFilterMenu={false} body={(rowData) => { return rowData.company ? rowData.company.name : '' }} filterPlaceholder='Unternehmen suchen' ></Column>
						<Column field="name" filter sortable showFilterMenu={false} style={{ width: '14rem' }} header="Name" filterPlaceholder='Fallname suchen'></Column>
						<Column className='w-5rem' sortable filter field='millisecondsCreatedAt' showFilterMenu={false} filterElement={createdFilterTemplate} body={renderCreatedDate} onFilterClear={() => setFilterCreatedValues(null)} header="Falldatum" ></Column>
						<Column className='w-6rem' sortable sortFunction={sortStatus} filter field="status" filterElement={statusRowFilterTemplate} showFilterMenu={false} header="Status" body={renderStatus}></Column>
						<Column className='open-icon justify-content-center w-3rem p-0' body={renderOpenLink}></Column>
					</DataTable>
				</div>
			</div>
		</div>
	)

}