import { ConfirmDialog } from '../Components/ConfirmDialog'
import { useState } from 'react'
import { format, parseISO } from 'date-fns';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { getData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ENUM_ICONS } from '../Components/Icons';

const numberFormat = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);

export const JobOpeningCard = ({ item, onEditHandler, onDeleteHandler, readonly }) => {
	const currentUser = useCurrentUser();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const configurationsList = useConfigurationsList();
	const addBreadcrumb = useBreadcrumbsAdd();

	const onOpenHandler = async (id) => {
		await getData('job-openings', id).then((jobOpening) => {
			addBreadcrumb(`${jobOpening.description}`, `${ENUM_ROUTES.JOBPROFILES}/` + id);
		})
	}

	const renderContent = () => {
		const { openingDate, jobType, salary, location } = item
		const _jobType = configurationsList.JobOpeningTypeList ? configurationsList.JobOpeningTypeList.find(entry => entry.alias === jobType) : '';
		return <div className="flex align-content-end flex-column h-8rem py-1 px-2 overflow-x-auto">
			<div className={openingDate ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.CALENDAR} /></div><div className='col '>ab {format(parseISO(openingDate), 'yyyy-MM-dd')}</div></div>
			<div className={jobType ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.JOBTYPE} /></div><div className='col '>{_jobType ? _jobType.display : ''}</div></div>
			<div className={salary ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.EURO} /></div><div className='col '>{numberFormat(salary)}</div></div>
			<div className={location ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.LOCATION} /></div><div className='col '>{location}</div></div>
		</div>
	}

	const renderTitle = () => {
		return <div className="flex title">
			<div className="flex flex-grow-1 font-bold">
				{item.description}
			</div>
			<div className="flex title-icons justify-content-end font-light">
				<i className={ENUM_ICONS.EYE + 'fa-lg text-white-500'} onClick={() => { onOpenHandler(item.id) }} />
				<i className={currentUser.permissions === 'READER' || readonly ? 'hidden' : (ENUM_ICONS.PENCIL + 'title-pencil')} onClick={() => { onEditHandler(item.id) }} />
				<i className={currentUser.permissions === 'READER' || readonly ? 'hidden' : (ENUM_ICONS.TRASH + 'title-trash')} onClick={() => { setDisplayConfirmDialog(true) }} />
			</div>
		</div>
	}

	return (<>
		<ConfirmDialog title='Stellenprofil deaktivieren' message={`Sind Sie sicher,\nStellenprofil ${item.description}\nzu deaktivieren?'`} labelOk='Ja'
			handleOnClick={() => onDeleteHandler(item)} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

		<div className="eaa-card jobopening-card shadow-2">
			{renderTitle()}
			{renderContent()}
		</div>
	</>)
}