import { format, parseISO } from "date-fns";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { useStatusFilterUpdate } from "../EAACase/StatusFilterContext";
import { getData, patchData } from "../feathers";
import { JobOpeningForm } from "../JobOpenings/JobOpeningForm";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import logger from "../Utils/logger";
import { useCallbackPrompt } from "../Utils/useCallbackPrompt";
import { CompanyJobOpenings } from "./CompaniesJobOpenings";
import { CompanyAddresses } from "./CompanyAddresses";
import { CompanyContactPersons } from "./CompanyContactPersons";
import { CompanyForm, emptyCompany } from "./CompanyForm";
import { ENUM_ICONS } from "../Components/Icons";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import exportToPDF from "../Utils/exportToPDF";

export const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];

export const CompaniesData = ({ companyIdParent }) => {

	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const { companyId } = useParams();
	const [company, setCompany] = useState(emptyCompany);
	const [addresses, setAddresses] = useState([]);
	const [items, setItems] = useState([]);
	const [contactPersons, setContactPersons] = useState([]);
	const [jobOpening, setJobOpening] = useState();
	const [companyForm, setCompanyForm] = useState();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayJobOpeningDialog, setDisplayJobOpeningDialog] = useState(false);
	const [companyFormHasErrors, setCompanyFormHasErrors] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const statusFilterUpdate = useStatusFilterUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();

	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
	const [errorMessage, setErrorMessage] = useState();


	useEffect(() => {
		if (companyIdParent) {
			getData("companies", companyIdParent).then((company) => {
				setCompany(company);
				setAddresses(company.addresses ? company.addresses : [])
				setContactPersons(company.contactPersons ? company.contactPersons : [])
				setJobOpening(company.jobOpenings ? company.jobOpenings : [])
			});
		} else if (companyId && companyId !== 'new') {
			getData("companies", companyId).then((company) => {
				setCompany(company);
				setAddresses(company.addresses ? company.addresses : [])
				setContactPersons(company.contactPersons ? company.contactPersons : [])
				setJobOpening(company.jobOpenings ? company.jobOpenings : [])
			});
		}

	}, [companyId])

	useEffect(() => {
		const items = currentUser.permissions === 'READER' ? [] : [
			{ label: 'Bearbeiten', disabled: company && !company.active, icon: ENUM_ICONS.PENCIL, command: handleEditCompany },
		]
		items.push({ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportCompany },
		)
		items.push({ separator: true })
		if (currentUser.permissions !== 'READER' && company && company.active) {
			items.push({ label: 'Inaktiv setzen', icon: ENUM_ICONS.TRASH, disabled: (company && company.eaaCases && company.eaaCases.length > 0), command: () => setDisplayConfirmDialog(true) })
		} else if (company && !company.active) {
			items.push({ label: 'Aktiv setzen', icon: ENUM_ICONS.REACTIVATE, command: () => setDisplayConfirmDialog(true) })
		}
		setItems(items);
	}, [company])

	const onUpdateCompany = async () => {
		await getData('companies', company.id).then((company) => {
			setCompany(company)
			setAddresses(company.addresses ? company.addresses : [])
			setContactPersons(company.contactPersons ? company.contactPersons : [])
			setJobOpening(company.jobOpenings ? company.jobOpenings : [])
			setShowDialog(false)
		}).catch((error) => {
			logger.error('(CompaniesData->onUpdateCompany) Fehler beim Holen eines Unternehmens: ' + error.message)
		})
	}

	const handleSelectExistingCompany = async (companyId) => {
		await getData('companies', companyId).then((company) => {
			setCompany(company)
			setAddresses(company.addresses ? company.addresses : [])
			setContactPersons(company.contactPersons ? company.contactPersons : [])
			setJobOpening(company.jobOpenings ? company.jobOpenings : [])
			setShowDialog(false)
		}).catch((error) => {
			logger.error('(CompaniesData->handleSelectExistingCompany) Fehler beim Holen eines Unternehmens: ' + error.message)
		})

	}

	const patchCompany = async () => {
		if (companyForm.region) {
			companyForm.regionId = companyForm.region.id
		}

		await patchData('companies', companyForm, currentUser).then((patchedCompany) => {
			setCompany(patchedCompany);
			setDisplayDialog(false);
			setShowDialog(false);
		}).catch((error) => {
			logger.error('Fehler beim Speichern des Unternehmens: ' + error.message)
			setErrorMessage('Fehler beim Speichern des Unternehmens: ' + error.message)
		});
	}

	const onClickEAACases = () => {
		statusFilterUpdate({ company: company.name, responsible: null });
		updateBreadcrumbs({ home: ENUM_ICONS.FOLDER_OPEN, items: [{ label: 'Fallverwaltung', route: ENUM_ROUTES.EAACASES }] })
	}

	const handleEditCompany = () => {
		setDisplayDialog(true);
	}

	const handleExportCompany = async () => {
		await exportToPDF('COMPANY', company, null);
	}

	const toggleCompanyStatus = async () => {
		company.active = company.active ? 0 : 1;
		await patchData('companies', company).then(() => {
			console.debug('company deactivated:');
			onUpdateCompany();
		})
	}
	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const patchJobOpening = async () => {
		jobOpening.eaaCaseId = (jobOpening.eaaCase ? jobOpening.eaaCase.id : jobOpening.eaaCaseId)
		await patchData('job-openings', jobOpening, currentUser).then(() => {
			onUpdateCompany()
		})
	}

	const renderDataForRead = () => {
		const status = listStatus.find(entry => entry.alias === company.active);
		return <div className='flex flex-column'>
			<ConfirmDialog title={'Unternehmen ' + (company.active ? 'deaktivieren' : 'reaktivieren')} message={'Wollen Sie das Unternehmen ' + (company.active ? 'deaktivieren?' : 'reaktivieren?')} labelOk='Ja'
				handleOnClick={toggleCompanyStatus} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={company.name} serviceId={company.id} serviceName='companies' userId={currentUser.id} />
			<Dialog header='Unternehmensdetails ändern' visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-company-details" className='eaa-inputdialog'>
				<CompanyForm company={company} editmode={true} setFormHasErrors={setCompanyFormHasErrors} setCompanyForm={setCompanyForm} handleSelectExistingCompany={handleSelectExistingCompany} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={setShowDialog} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => { setShowDialog(false); setDisplayDialog(false) }} />
					<Button onClick={patchCompany} disabled={companyFormHasErrors} className="flex-order-0 ml-3 " label={company && company.id ? 'Speichern' : 'Neu anlegen'} />
				</div>
			</Dialog>
			<Dialog header='Neues Stellenprofil anlegen' visible={displayJobOpeningDialog} onHide={() => setDisplayJobOpeningDialog(false)} id="dialog-jobopening-details" className="eaa-inputdialog" >
				<JobOpeningForm companyId={company.id} editmode={true} setFormHasErrors={setCompanyFormHasErrors} setJobOpening={setJobOpening} key={'JobOpeningForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2" >
					<Button type="button" className="flex-order-1" label='Abbrechen' onClick={() => setDisplayJobOpeningDialog(false)} />
					<Button onClick={patchJobOpening} disabled={companyFormHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
			<div className='flex justify-content-end mb-4 gap-2'>
				<Button onClick={onClickEAACases} icon={ENUM_ICONS.FOLDER_OPEN_O} className='flex text-white ' style={{ backgroundColor: '#494C4F' }} label='Fälle des Unternehmens anzeigen' />
				<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} className='flex text-white ' style={{ backgroundColor: '#494C4F' }} label='Wiedervorlage' />
			</div>
			<div className='flex grid'>
				<div className='col flex  flex-column  text-4xl '>
					<div className='flex justify-content-center font-semibold'>
						{company.name}
					</div>
					<div className='flex justify-content-center text-lg m-2 gap-2'>
						<div>{'#UN' + company.id + ' erstellt am ' + (company.createdAt ? format(parseISO(company.createdAt), 'yyyy-MM-dd') : '')}</div>
						<Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
					</div>
				</div>
				<div className='col-fixed w-2rem'>
					<OverlayPanelMenu items={items} />
				</div>
			</div>
			<div className='flex card-container blue-container overflow-hidden my-4'>
				<FieldInfo label='Telefon' content={company.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-2x'} />} />
				<FieldInfo label='Fax  ' content={company.fax} icon={<i className={ENUM_ICONS.FAX + 'fa-2x'} />} />
				<FieldInfo label='E-Mail' content={company.mail} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-2x'} />} />
			</div>
			<div className='flex card-container blue-container overflow-hidden'>
				<FieldInfo className='w-12rem' label='Mitarbeiter*innen' content={company.employees} />
				<FieldInfo className='w-12rem' label='SbM' content={company.employeesSbM} />
				<FieldInfo label='Kammer' content={company.chamber} />
				<FieldInfo label='Betriebsnummer' content={company.chamberNo} />
				<FieldInfo label='EAA-Region' content={company.region.name} />
			</div>
		</div>
	}

	return (
		<div className="flex m-0 w-full h-full  overflow-y-auto select-none">
			<div className='mx-auto eaa-maincontent flex flex-column'>
				{!company ? '' :
					renderDataForRead()
				}
				<CompanyAddresses readonly={!company.active} company={company} addresses={addresses ? addresses : []} numCarousel={3} onUpdateCompany={onUpdateCompany} />
				<CompanyContactPersons readonly={!company.active} company={company} contactPersons={contactPersons ? contactPersons : []} numCarousel={3} onUpdateCompany={onUpdateCompany} />
				{configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING === '1'
					? <CompanyJobOpenings readonly={!company.active} company={company} jobOpenings={jobOpening ? jobOpening : []} numCarousel={3} onUpdateCompany={onUpdateCompany} />
					: ''}

			</div>
		</div>

	)
}
