import React, { useContext, useState, useEffect } from "react";
import { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const UserContext = React.createContext();

const servicename = 'users';
const listenernames = ['created', 'patched'];
const providername = 'UserProvider';

export function useUserList() {
	return useContext(UserContext);
}

export function UserProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();

	let isMounted = true;

	useEffect(() => {
		if (listItemChanged) {
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		LoggerContext.debug(`########### ${providername} useEffect`);
		findData(servicename, { isAdmin: { $ne: 1 } }).then((_list) => {
			if (isMounted) {
				setList(_list);
			}
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		return () => { updateServiceListener(servicename, listenernames, null); }
	}, [isMounted])
	return (
		<UserContext.Provider value={list}>
			{children}
		</UserContext.Provider>
	)
}