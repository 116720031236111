import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";
import { getData, patchData } from "../feathers";
import logger from "../Utils/logger";
import { AddressesForm } from "./AddressesForm"



export const AddressDialog = ({ addressId, displayDialog, setDisplayDialog, handleSave }) => {

	const hideDialog = () => {
		setDisplayDialog(false)
	}

	return (<>
		<Dialog header={addressId ? 'Adresse ändern' : 'Neue Adresse hinzufügen'} visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-address" className='eaa-inputdialog' >
			<AddressesForm addressId={addressId} handleSave={handleSave} handleCancel={hideDialog} />
		</Dialog>
	</>)
}

export const handleSaveCompanyAddress = async (company, changedAddressId) => {
	/* Wenn company bereits gespeichert wurde -> NUR Assoziation speichern*/
	if (company.id && changedAddressId) {
		await getData('addresses', changedAddressId).then(async (address) => {
			const companies = address.companies ? address.companies : []
			companies.push(company)
			address.companies = companies
			await patchData('addresses', address).then((patchedAddress) => {
				console.debug('CompanyAddress handleSave patched address:');
			})


		}).catch((error) => {
			logger.error('Error while handleSave in ComapnyAddresses: ' + error.message)
		})
	}
}