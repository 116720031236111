import { useBreadcrumbClick, useBreadcrumbs } from "../Persistence/Breadcrumbs";



export const Breadcrumbs = () => {

	const breadcrumbs = useBreadcrumbs();
	const onBreadcrumbClick = useBreadcrumbClick();

	const onClick = (route) => {

		if (route) {
			onBreadcrumbClick(route);
		}

	}


	const renderItems = () => {
		const items = [];
		if (breadcrumbs && breadcrumbs.items) {
			let counter = 0;
			for (let item of breadcrumbs.items) {
				++counter;
				items.push(<li key={'breadcrumb_angle_' + counter} className="px-2"><i className={`fa fa-angle-right ${counter === breadcrumbs.items.length ? 'color-text-blue' : 'text-500'}`}></i></li>)
				items.push(<li key={'breadcrumb_label_' + counter} className="px-2"><a onClick={() => { onClick(item.route) }} className={`cursor-pointer ${counter === breadcrumbs.items.length ? 'color-text-blue' : 'text-500'} white-space-nowrap`}>{item.label}</a></li>)
			}
		}
		return items;

	}

	return (
		<ul className="list-none p-0 m-0 surface-card flex align-items-center font-medium overflow-x-auto">
			<li key='breadcrumb_home' className="pr-3">
				<a className="cursor-pointer">
					<i className={`${breadcrumbs.home.icon} text-500`}></i>
				</a>
			</li>
			{renderItems()}
		</ul>
	)
}