import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils";

export const InputField = ({ id, type, label, formik, className }) => {
	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};
	return (
		<div className="field col">
			<label htmlFor={id} className='block'>{label}</label>
			<InputText id={id} name={id} type={type} value={formik.values[id]} onChange={(e) => { formik.handleChange(e) }} className={classNames({ 'p-invalid': isFormFieldValid(id) }) + (className ? className : ' w-full')} autoComplete="off" />
			{getFormErrorMessage(id)}
		</div>
	)

}

