import { max, startOfYear } from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import { useCurrentUser } from "../Persistence/CurrentUserContext";

export const StatisticPerson = ({ onClickCreateStatistic }) => {

	const currentUser = useCurrentUser();
	const [dateFrom, setDateFrom] = useState(startOfYear(new Date()));
	const [dateTo, setDateTo] = useState(new Date());

	return (
		<div className="card shadow-2 p-4 my-4 bg-white">
			<div className='font-bold mb-4'>Persönliche Statistik</div>
			<div className="p-fluid grid formgrid gap-4">
				<div className="field col-fixed w-15rem">
					<label htmlFor="dateCommonFrom">Von</label>
					<Calendar maxDate={dateTo} id="dateCommonFrom" value={dateFrom} onChange={(e) => setDateFrom(e.value)} showIcon dateFormat="dd.mm.yy" />
				</div>
				<div className="field col-fixed w-15rem">
					<label htmlFor="dateCommonTo">Bis</label>
					<Calendar minDate={dateFrom} maxDate={new Date()} id="dateCommonTo" value={dateTo} onChange={(e) => setDateTo(e.value)} showIcon dateFormat="dd.mm.yy" />
				</div>
			</div>
			<div className='flex '>
				<Button disabled={dateFrom == null || dateTo == null} onClick={() => onClickCreateStatistic(dateFrom, dateTo, currentUser.id)} className='flex  text-white' label='Statistik generieren' />
			</div>
		</div>
	)
}