import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const listenernames = ['created', 'patched'];
const providername = 'OrganizationsProvider';
const servicename = 'organizations';
const OrganizationsContext = React.createContext();
const OrganizationsSelectionContext = React.createContext();

export function useOrganizationsSelectionList() {
	return useContext(OrganizationsSelectionContext);
}

export function useOrganizationsList() {
	return useContext(OrganizationsContext);
}

export function OrganizationsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();

	let isMounted = true;

	useEffect(() => {
		LoggerContext.info('########### ' + providername + ' useEffect listItemChanged update');
		if (listItemChanged) {
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		LoggerContext.info('########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
		setListSelection(list.filter((entry) => entry.active))
	}, [list])

	useEffect(() => {
		LoggerContext.info(`########### ${providername} useEffect`);
		getData(servicename).then((_list) => {
			if (isMounted) {
				setList(_list);
				setListSelection(_list.filter((entry) => entry.active))
			}
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
		return () => { updateServiceListener(servicename, listenernames, null); }
	}, [isMounted])
	return (
		<OrganizationsContext.Provider value={list}>
			<OrganizationsSelectionContext.Provider value={listSelection}>
				{children}
			</OrganizationsSelectionContext.Provider>
		</OrganizationsContext.Provider>
	)
}