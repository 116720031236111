import { Card } from "primereact/card"
import { useEffect, useState } from "react";
import { AddressDialog, handleSaveCompanyAddress } from "../Addresses/AddressDialog";
import { ENUM_ICONS } from "../Components/Icons";
import { ContactPersonDialog } from "../ContactPersons/ContactPersonDialog";
import { getData } from "../feathers";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { CompanyAddresses } from "./CompanyAddresses"
import { CompanyChangeDialog } from "./CompanyChangeDialog";
import { CompanyContactPersons, handleSaveCompanyContactPerson } from "./CompanyContactPersons"

export const CompanySheet = ({ company, onUpdateCompany, readonly }) => {
	const currentUser = useCurrentUser();
	const addBreadcrumb = useBreadcrumbsAdd()
	const [displayDialogAddress, setDisplayDialogAddress] = useState(false);
	const [displayDialogContactPerson, setDisplayDialogContactPerson] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [contactPersons, setContactPerson] = useState([])

	useEffect(() => {
		//console.log('CompanySheet readonly: ' + readonly)
	})

	useEffect(() => {
		if (company && company.contactPersons) {
			setContactPerson(company.contactPersons)
		}
	}, [company])

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Unternehmen</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>{company.name} </div>
				<div className='flex '>
					<div className='hidden w-1rem' onClick={handleSelectCompany}><i className={ENUM_ICONS.EYE} /></div>
					<div className={currentUser.permissions === 'READER' || readonly ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayCompanyDialog(true)}><i className={ENUM_ICONS.PENCIL} /></div>
				</div>

			</div>
		</div>
	}

	const renderHeaderContactPersons = () => {
		return <div className='flex justify-content-between border-bottom-1'>
			<div className='flex font-medium  mb-2 '>Ansprechpartner*innen ({contactPersons.length}) </div>
			<div className={currentUser.permissions === 'READER' || readonly ? 'hidden' : 'flex w-1rem'} onClick={() => { setDisplayDialogContactPerson(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
		</div>
	}

	const renderHeaderAddresses = () => {
		return <div className='flex justify-content-between border-bottom-1'>
			<div className='flex font-medium  mb-2 '>Adressen ({company && company.addresses ? company.addresses.length : 0}) </div>
			<div className={currentUser.permissions === 'READER' || readonly ? 'hidden' : 'flex w-1rem'} onClick={() => { setDisplayDialogAddress(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
		</div>
	}

	const handleSelectCompany = async () => {
		await getData('companies', company.id).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
		})
	}

	const handleSaveContactPerson = (companyContactPersonId) => {
		handleSaveCompanyContactPerson(companyContactPersonId, company.Id);
		setDisplayDialogContactPerson(false);
		onUpdateCompany();
	}

	const handleSaveAddress = (addressId) => {
		handleSaveCompanyAddress(company, addressId);
		setDisplayDialogAddress(false);
		onUpdateCompany();
	}

	return (
		<>
			<Card className="companysheet card-sheet border-none mb-3" header={renderHeader}>
				<div className={company.phone ? 'grid' : 'hidden'}>
					<div className="col-fixed">Telefon</div>
					<div className="col">{company.phone}</div>
				</div>
				<div className={company.fax ? 'grid' : 'hidden'}>
					<div className="col-fixed">Fax</div>
					<div className="col">{company.fax}</div>
				</div>
				<div className={company.mail ? 'grid' : 'hidden'}>
					<div className="col-fixed">E-Mail</div>
					<div className="col">{company.mail}</div>
				</div>
				<div className="grid">
					<div className="col-fixed">Mitarbeiter</div>
					<div className="col">{company.employees}</div>
				</div>
				<div className="grid">
					<div className="col-fixed">SbM</div>
					<div className="col">{company.employeesSbM}</div>
				</div>
				<div className={company.chamber ? 'grid' : 'hidden'}>
					<div className="col-fixed">Kammer</div>
					<div className="col">{company.chamber}</div>
				</div>
				<div className={company.chamberNo ? 'grid' : 'hidden'}>
					<div className="col-fixed">Betriebsnummer</div>
					<div className="col">{company.chamberNo}</div>
				</div>
				<div className="grid">
					<div className="col-fixed">Region</div>
					<div className="col">{company.region.name}</div>
				</div>
			</Card>
			<CompanyChangeDialog companyId={company ? company.id : null} displayCompanyDialog={displayCompanyDialog} setDisplayCompanyDialog={setDisplayCompanyDialog} handleOnCompanyPatched={onUpdateCompany} />
			<Card className="companysheet card-sheet mt-2" header={renderHeaderContactPersons}>
				<CompanyContactPersons readonly={readonly} hideDivider={true} contactPersons={contactPersons} company={company} numCarousel={1} onUpdateCompany={handleSaveContactPerson} />
			</Card>
			<ContactPersonDialog companyId={company ? company.id : null} displayDialog={displayDialogContactPerson} setDisplayDialog={setDisplayDialogContactPerson} handleSave={handleSaveContactPerson} />

			<Card className="companysheet card-sheet bg-white mt-2" header={renderHeaderAddresses}>
				<CompanyAddresses readonly={readonly} hideDivider={true} company={company} addresses={company && company.addresses ? company.addresses : []} numCarousel={1} onUpdateCompany={onUpdateCompany} />
			</Card>
			<AddressDialog addressId={null} displayDialog={displayDialogAddress} setDisplayDialog={setDisplayDialogAddress} handleSave={handleSaveAddress} />
		</>
	)

}